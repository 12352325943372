import './App.css';
import { TypeAnimation } from 'react-type-animation';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          ELAP1804 <br/>
          Elevating Haitian voices. <br/>
          Coming Soon.
        </p>
      </header>
    </div>
  );
}

export default App;
